<template>
    <div style="width: 100%;height:auto;">
        <div style="width: 100%;background: #FFFFFF;">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                    <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 模板信息</div>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 160px;background:url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/template.png')">
            <div style="display: flex;justify-content: center;padding-top: 34px;">
                <div style="margin: 0px auto;">
                    <div style="width: 100%;display: flex;color: #666666;text-align: center;">
                        <div v-for="(item,index) in ListType" :key="index" >
                            <div style="width:116px;height: 37px;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="add_type(item.type)" :class="{ Fcti: item.type === typeId }">{{item.label}}</div>
                        </div>
                    </div>
                    <div style="width: 880px;position: relative;">
                        <el-input v-model="like" prefix-icon="el-icon-search" placeholder="请输入你想要查询的内容以获得帮助......" style="height: 40px;width: 100%;font-size: 16px;border-radius:0px;border: 2px solid #2970FF;">
                            <template slot="append"><el-button type="primary" @click="getBusinessInfoByType" style="background: #2970FF;color: #FFFFFF;border-radius:0px;height: 41px;margin: -10px -21px;">搜索</el-button></template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1500px;margin: 0 auto;padding-top: 10px;">
            <div style="width: 100%;height: 60px;background: #FFFFFF;">
                <div style="display: flex;">
                    <div class="hidden">信息分类</div>
                    <div v-for="(label,index) in type_list" :key="index" style="margin-top: 20px;">
                        <div @click="add_type_list(label.value)" :style="Typelist == index?'color:#2970FF':'color:#666666'" class="op sc hand" :class="index == 0?'typewidth1':'typewidth2'" style="font-size: 18px;float: left;margin-left: 40px;cursor: pointer;font-weight: bold">{{label.label}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1500px;margin: 0 auto;padding-top: 10px;">
            <div style="display: flex; flex-wrap: wrap; margin-right: -15px;">
                <div v-for="(item,index) in InfoList" :key="index" @click="b('/index/details/'+item.id)" class="op sc hand" style="width: 490px;height: 300px;background: #FFFFFF;margin-bottom: 10px; margin-right: 15px;cursor: pointer">
                    <div style="font-size: 12px;height: 26px;border-radius: 8px;margin-top: 20px;margin-left: 30px;float: left">
                        <el-tag v-if="item.type == 1" style="background: #3C56CF;color: #FFFFFF;border-radius:8px">招标信息</el-tag>
                        <el-tag v-if="item.type == 2" style="background: #FE9544;color: #FFFFFF;border-radius:8px">采购信息</el-tag>
                        <el-tag v-if="item.type == 3" style="background: #40AC97;color: #FFFFFF;border-radius:8px">招募信息</el-tag>
                        <el-tag v-if="item.type == 4" style="background: #9079C1;color: #FFFFFF;border-radius:8px">商务合作</el-tag>
                        <el-tag v-if="item.type == 5" style="background: #E24F48;color: #FFFFFF;border-radius:8px">招商信息</el-tag>
                        <el-tag v-if="item.type == 6" style="background: #59C3D8;color: #FFFFFF;border-radius:8px">需求信息</el-tag>
                    </div>
                    <div style="font-size: 18px;float: right;margin-top: 25px;margin-right: 30px;">
                        <span style="color: #999999;">预算：<span style="color: #F44747 ">￥200000</span>元</span>
                    </div>
                    <div style="color: #444444;font-size: 20px;font-weight: 400;clear: both;padding-top: 20px;margin-left: 30px;width: 440px;height:54px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.title}}</div>
                    <div style="height: 100px;">
                        <div class="bulink">项目地点：<span style="color: #666666">北京市</span></div>
                        <div class="bulink">发布时间：<span style="color: #666666">{{item.createTime | parseTime('{y}-{m}-{d}')}}</span></div>
                    </div>
                    <div style="width: 430px;height: 44px;background: #F5F5F5;margin-left: 30px;opacity: 1">
                        <div style="font-size: 16px;color: #999999;padding-top: 13px;margin-left: 10px;">距结束剩:  <span v-html="getTimeRemaining(item.endTime)"></span></div>
                    </div>
                </div>
                <div v-if="InfoList.length <= 0" style="margin: 0 auto">
                     <NoData></NoData>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {formatTime, parseTime} from '@/utils/utils';
import AvatarMenu from "../AvatarMenu";
import NoData from "../NoData";
export default {
    components: {AvatarMenu,NoData},
    filters: {
        parseTime, formatTime
    },
    computed: {

    },
    data () {
        return {
            ListType: [
                { type: 1, label: "招标信息",  },
                { type: 2, label: "采购信息",},
                { type: 3, label: "招募信息", },
                { type: 4, label: "商务合作", },
                { type: 5, label: "招商信息", },
                { type: 6, label: "需求信息", },
            ],
            typeId:1,
            type_list:[
                {label:'全部',value:0},
                {label:'招标信息',value:1},
                {label:'采购信息',value:2},
                {label:'招募信息',value:3},
                {label:'商务合作',value:4},
                {label:'招商信息',value:5},
                {label:'需求信息',value:6},
            ],
            Typelist:0,
            like:"",
            InfoList:[],
            pageSize: 21,
            currentPage: 1,
        }
    },
    mounted:function(){
        this.getBusinessInfoByType();
        // 跳转在顶部
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    },
    methods:{
        add_type(typeId){
            this.typeId = typeId;
        },

        add_type_list(value){
            this.Typelist = value;
            this.currentPage = 1;
            this.getBusinessInfoByType()
        },
        handleScroll() {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                this.getMoreData();
            }
        },

        getBusinessInfoByType:function(){
            var that = this;
            that.newApi.getBusinessInfoByType({
                type:0,
                typeTwo:that.Typelist,
                like:that.like,
                page:that.currentPage,
                pageSize:that.pageSize,
            }).then((res)=>{
                if (res.isSuccess == 1){
                    that.InfoList = res.data.data;
                    that.like = ""
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        getMoreData(){
            var that = this;
            const nextPage = that.currentPage + 1;
            that.newApi.getBusinessInfoByType({
                type: 0,
                typeTwo: that.Typelist,
                page: nextPage,
                pageSize: that.pageSize,
            }).then((res) => {
                if (res.isSuccess == 1){
                    if (res.data.data.length){
                        that.InfoList = that.InfoList.concat(res.data.data);
                        that.currentPage = nextPage;
                    }else {
                        that.utils.sus("数据已加载完成");
                    }
                }
            })
        },
        // 模板信息时间事件
        getTimeRemaining(endTime) {
            const now = new Date();
            const end = new Date(endTime);
            const timeDiff = end - now;

            if (timeDiff <= 0) {
                return "<span style='color: red;font-weight: 600'>已结束</span>";
            }

            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

            return `<span style='color: red;font-weight: 600;font-size: 18px'>${days}</span>天<span style='color: red;font-weight: 600'>${hours}</span>小时<span style='color: red;font-weight: 600'>${minutes}</span>分钟`;
        },
    }
}
</script>
<style scoped>
.Fcti {
    width: 100%;
    height: 37px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #2970FF;
    border-bottom: none;
    color:#2970FF;
    font-size: 16px;
    box-sizing: border-box;
}
.hidden{
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0 0 30px;
}

.typewidth1 {
    width:36px
}
.typewidth2 {
    width:72px
}
.bulink {
    color: #999999;
    font-size: 18px;
    margin-left: 30px;
    margin-top: 15px;
}
</style>